.TabBlog {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.TabBlogPageImg {
    width: 100%;
    aspect-ratio: 751 / 839;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}


.BlogItem {
    width: 90%;
    aspect-ratio: 338 / 129;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.BlogItemImg {
    width: 40%;
    aspect-ratio: 266 / 190;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.BlogItemContent {
    width: 56%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.BlogItemTitle {
    color: white;
    font-size: 1.7vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.BlogItemDesc {
    color: #FFE48A;
    font-size: 1.4vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.BlogItemReadMore {
    color: #6CFF00;
    font-size: 1.7vh;
    align-self: flex-end;
}
