

.Header {
    width: 100%;
    aspect-ratio: 3;
    background: url("../../../assets/img/bg1.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
}

.HeaderIcon {
    width: 30%;
    aspect-ratio: 251/96;
    display: flex;
    background: url("../../../assets/img/icon1.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 10px;
    margin-top: 10px;
}

.HeaderTabs {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.HeaderTab {
    color: white;
    font-weight: bold;
    /*font-size: 2.1vh;*/
    cursor: pointer;
    margin-left: 6px;
    margin-right: 6px;
}
