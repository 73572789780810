.TabHotGames {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.ContentHotGames {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5vh;
}

.ContentHotGamesImg {
    width: 100%;
    aspect-ratio: 750 / 608;
}

.HotGamesImg {
    width: 50%;
    aspect-ratio: 440 / 43;
    display: flex;
    background: url("../../../assets/hot/spades 拷贝 2@2x.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.HotGamesRow {
    display: flex;
    justify-self: center;
    align-self: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.HotGameItem {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FF6682;
}

.HotGameItemImg {
    height: 15vh;
}

.HotGameItemTitle {
    color: #FFE48A;
}

.DownloadBtn {
    width: 70%;
    aspect-ratio: 620/148;
    background: url("../../../assets/img/download1.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}

