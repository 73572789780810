.BlogRoot {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    align-items: center;
}

.BlogContent {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.BlogTitle {
    color: black;
    font-weight: bold;
    margin-top: 3vh;
    margin-bottom: 1vh;
}

.BlogDate {
    font-size: 2.1vh;
    color: #9B9B9B;
    margin-bottom: 1vh;
}

.BlogTag {
    width: 35vh;
    height: 3.5vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6vh;
    color: #FF7800;
    background: url("../../../../assets/blog/tag_bg.png") no-repeat;
    background-size: 100% 100%;
}

.BlogNormalText {
    color: #696969;
    font-size: 2vh;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.BlogTitleText {
    color: #696969;
    font-weight: bold;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.BlogSubTitleText {
    color: #696969;
    font-weight: bold;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.BlogImg {
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}
