.TabFaq {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.FaqTitle  {
    width: 50%;
    aspect-ratio: 400 / 108;
    margin-top: 5vh;
    margin-bottom: 5vh;
    background: url("../../../assets/img/faq_title.png") no-repeat;
    background-size: 100% 100%;
}

.FaqItemTitle {
    width: 80%;
    color: white;
}

.FaqItemContent {
    width: 80%;
    color: #FF6682;
    margin-bottom: 8vh;
}
