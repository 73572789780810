
.DownloadPlayBar {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.DownloadBtnS {
    width: 40%;
    aspect-ratio: 154/48;
    background: url("../../../assets/img/download_s.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}

.PlayNowBtnS {
    width: 40%;
    aspect-ratio: 154/48;
    background: url("../../../assets/img/playnow_s.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
