.TabDownload {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.ContentDownload {
    width: 100%;
    color: #FFCC00;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vh;
}

.ContentDownloadImg {
    width: 100%;
    aspect-ratio: 750 / 831;
}

.HowToInstallImg {
    width: 50%;
    aspect-ratio: 476 / 43;
    display: flex;
    background: url("../../../assets/download/spades 拷贝 2@2x.png") no-repeat;
    background-size: 100% 100%;
}


.Step1Text {
    color: white;
    width: 90%;
}

.Step2Text {
    width: 90%;
}

.Step3Text {
    color: white;
    width: 90%;
}

.Step4Text {
    width: 90%;
}

.StepRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    aspect-ratio: 4 / 3;
    margin-top: 5vh;
}

.StepImg {
    width: 45%;
    aspect-ratio: 327 / 589;
}
