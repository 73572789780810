.BackToTop {
    width: 50%;
    cursor: pointer;
    aspect-ratio: 464 / 86;
    align-self: center;
    margin-top: 5vh;
    background: url("../../../../assets/blog/back_top.png") no-repeat;
    background-size: 100% 100%;
}

.BackToBlog {
    width: 50%;
    cursor: pointer;
    aspect-ratio: 464 / 86;
    align-self: center;
    margin-top: 2vh;
    margin-bottom: 3vh;
    background: url("../../../../assets/blog/back_blog.png") no-repeat;
    background-size: 100% 100%;
}

.NextAndPrevious{
    width: 80%;
    margin-top: 2vh;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.PreviousBlog {
    width: 40%;
    aspect-ratio: 216 / 53;
    cursor: pointer;
    background: url("../../../../assets/img/btn_previous.png") no-repeat;
    background-size: 100% 100%;
}

.NextBlog {
    width: 27%;
    aspect-ratio: 143 / 53;
    cursor: pointer;
    background: url("../../../../assets/img/btn_next.png") no-repeat;
    background-size: 100% 100%;
}
