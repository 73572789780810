.TabBottom {
    width: 100%;
    aspect-ratio: 750 / 1472;
    display: flex;
    flex-direction: column;
    background: url("../../../assets/img/bg4.png") no-repeat;
    background-size: 100% 100%;
    align-items: flex-start;
}

.ContentLink1 {
    color: #FFCC00;
    /*font-size: 3.5vh;*/
    margin-top: 5vh;
    margin-left: 4vh;
}

.ContentLink2 {
    color: #FF6682;
    /*font-size: 2.8vh;*/
    margin-top: 2vh;
    margin-left: 4vh;
    cursor: pointer;
}

.Line {
    width: 80%;
    height: 2px;
    background: url("../../../assets/img/line.png") no-repeat;
    background-size: 100% 100%;
    align-self: center;
    margin-top: 5vh;
}

.Reserved {
    color: #FF6682;
    align-self: center;
    margin-top: 2vh;
    margin-bottom: 13vh;
    font-size: 2vh;
}
