.TabHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.Content1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.Content1Img {
    width: 100%;
    aspect-ratio: 100 / 85;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.Content2 {
    width: 85%;
    aspect-ratio: 100 / 55;
    display: flex;
    background: url("../../../assets/img/bg6.png") no-repeat;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.Content3 {
    width: 90%;
    aspect-ratio: 100 / 65;
    display: flex;
    background: url("../../../assets/img/bg7.png") no-repeat;
    background-size: 100% 100%;
    align-items: end;
    justify-content: center;
    margin-bottom: 5vh;
}

.Content4 {
    width: 90%;
    aspect-ratio: 100 / 85;
    display: flex;
    background: url("../../../assets/img/bg5.png") no-repeat;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
}
