body {
  margin: 0;
  //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //-webkit-touch-callout: none;
  //-webkit-user-select: none;
  //-moz-user-select: none;
  //-ms-user-select: none;
  //-webkit-tap-highlight-color: transparent;
  //user-select: none;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

body > #root > div {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.Space {
  flex-grow: 1;
}

::-webkit-input-placeholder { /* WebKit browsers */
  color: #b4b4b4;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #b4b4b4;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #b4b4b4;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #b4b4b4;
}
