.Home {
  width: 100vw;
  height: 100vh;
  background: url("../assets/img/bg0.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HomeBody {
  height: 100%;
  background: #cdcdcd;
  overflow: scroll;
  background: url("../assets/img/bg0.png") no-repeat;
  background-size: 100% 100%;
}

.HomeBody {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.HomeBody::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.BottomBar {
  width: 100vw;
  height: 70px;
  background: url("../assets/img/juxing.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.GameIcon {
  width: 55px;
  height: 55px;
  background: url("../assets/img/icon.png") no-repeat;
  background-size: 100% 100%;
  margin-left: 10px;
}

.GameDesc {
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: 10px;
}

.GameName {
  font-weight: normal;
}

.GameRemark {
  font-weight: normal;
}

.GameDownload {
  width: 130px;
  height: 42px;
  background: url("../assets/img/download2.png") no-repeat;
  background-size: 100% 100%;
  margin-right: 15px;
  cursor: pointer;
}
